export default {
  first: `<figure class="md:flex bg-slate-100 rounded-xl p-8 md:p-0 dark:bg-slate-800">
  <img class="w-24 h-24 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto" src="/sarah-dayan.jpg" alt="" width="384" height="512">
  <div class="pt-6 md:p-8 text-center md:text-left space-y-4">
    <blockquote>
      <p class="text-lg font-medium">
        “Tailwind CSS is the only framework that I've seen scale
        on large teams. It’s easy to customize, adapts to any design,
        and the build size is tiny.”
      </p>
    </blockquote>
    <figcaption class="font-medium">
      <div class="text-sky-500 dark:text-sky-400">
        Sarah Dayan
      </div>
      <div class="text-slate-700 dark:text-slate-500">
        Staff Engineer, Algolia
      </div>
    </figcaption>
  </div>
</figure>`,

  second: `.example {
    @apply
      flex-1
      w-full

      px-4
      py-2
      m-2

      text-base
      text-gray-200
      placeholder-gray-400

      bg-white 
      border-transparent
      rounded-lg
      shadow-sm
      appearance-none

      focus:outline-none
      ring-2
      ring-gray-600
      focus:ring-gray-400
      focus:border-transparent
      dark:bg-gray-800
    ;
  }`,

}
