<template>
  <div class="ra-basic ra-basic-text">
    <h2>Tailwind presents itself completely wrong</h2>
    <p>
      That is why many still do not understand what opportunities Tailwind gives
    </p>
    <br />
    <br />
    <p>
      When developer first time hear about Tailwind and visit the site -
        <RaLink
          link="https://tailwindcss.com/"
          styled
        >
          tailwindcss.com
        </RaLink>
      ,
      from start they see how html tags get over-flooded with css tailwind classes, quoted example:
      <RaCode lang="html">
        {{ code.first }}
      </RaCode>
      and after seeing that absurdly long and unreadable lines,
      we read next:
      <RaQuote>
        Tailwind CSS is the only framework that I've seen <b>scale</b><br />
        on <b>large teams</b>. It’s easy to customize, adapts to any design,<br />
        and the build size is tiny
      </RaQuote>
      Obviously, the example of code we seen not even near of <b>scale</b> or <b>large teams</b>,
      anyone can imagine their project with 300-1500 components writing classes in that style, can this be even usable? Ofcource no
      <br />
      This long lines of css classes is hard to manage and significantly increasing project support cost
      <br />
      <br />
      Dont get me wrong, in my opinion - <b>Tailwind</b> is the best thing that happened to Frontend industry in years, i'm using it in every possible way, in real projects of any size
      <br />
      <br />
      Do we use it like its presented on first page of Tailwind site?
      <br />
      <b>No</b>, its unusable and no one truly can write and support such code
      <br />
      <br />
      So, how do we actually using it, on components and project of any size?
      <br />
      <br />
      Deeply inside Tailwind site, you can find examples like:
      <RaCode lang="css">
        {{ code.second }}
      </RaCode>
      And this is where real true power of Tailwind can be executed, this is how the words from quote becomes truth: <b>Tailwind CSS is the only framework that I've seen scale
        on large teams</b>
      <br />
      <br />
      From here you can use every bit of power that it gives you, and succcesfuly support the project without increasing support cost and even more - its slightly reduce the cost of support of the project, same as increasing speed of the delivering new features
      <br />
      <br />
      I think Tailwind team did huge mistake with site first page as a lot of experienced developers got scared of this way of using markup, and leave without getting this truly best CSS tool a shot
      <br />
      <br />
      P.S. IMO Tailwind present itself as a "css framework", and im strongly disagree with this formula. From my perspective it is not a framework but borderless and amazing css tool, that gives you ability to achive your goals without squaring you in typical "framework" borders and standarts like "we do input like this" or "we do this only like this"
      <br />
      <br />
      Anyway, I personally reccomending to use Tailwind from any given choice for Web based, PWA or Extension based apps
      <br />
      <br />
      Thanks for the reading
      <br />
      stay tuned
      <br />
      Bye bye
    </p>
    <br />
    <p>
      {{ authors.length > 1 ? 'Authors:' : 'Author:' }}
      <RaLink
        v-for="(author, key) in authors"
        :key="key"
        :link="author.link"
        follow
        styled
      >
        {{ author.nick }}
      </RaLink>
    </p>
    <div
      v-if="vote && vote.up >= 0 && vote.down >= 0"
      class="ra-vote-block"
    >
      <RaButton
        v-title="`${vote.up} votes`"
        vote
        vote-type="up"
        type="success"
        class="ra-vote-button"
        @click="makeVote(postId, true)"
      />
      <RaButton
        v-title="`${vote.down} votes`"
        vote
        vote-type="down"
        type="danger"
        class="ra-vote-button"
        @click="makeVote(postId, false)"
      />
    </div>
  </div>
</template>

<script>
import VTitle from 'v-title'

import RaCode from '@/components/elements/RaCode.vue'
import RaLink from '@/components/elements/RaLink.vue'
import RaQuote from '@/components/elements/RaQuote.vue'
import RaButton from '@/components/elements/RaButton.vue'

import fingerVote from '@/mixins/fingerVote'

import codeData from '@/components/articles/Tailwind/code'

export default {
  name: 'DefaultProperty',

  components: {
    RaCode,
    RaLink,
    RaQuote,
    RaButton
  },

  directives: {
    title: VTitle
  },

  mixins: [fingerVote],

  metaInfo: {
    title: 'Tailwind presents itself completely wrong',
    titleTemplate: '%s | Roman Almazov',

    meta: [
      {
        name: 'description',
        content: 'and why it is wrong in documentation?'
      },
      {
        name: 'og:title',
        content: 'Tailwind presents itself completely wrong'
      },
      {
        name: 'twitter:title',
        content: 'Tailwind presents itself completely wrong'
      },
      {
        name: 'og:description',
        content: 'and why it is wrong in documentation?'
      },
      {
        name: 'twitter:description',
        content: 'and why it is wrong in documentation?'
      },
      { name: 'og:url', content: 'https://ralmaz.pro/blog/tailwind' },
      {
        name: 'twitter:url',
        content: 'https://ralmaz.pro/blog/tailwind'
      },

      { name: 'og:type', content: 'article' },
      { name: 'article:published_time', content: '2023-05-27' },
      { name: 'article:modified_time', content: '2023-05-27' },
      { name: 'article:author', content: 'RALMAZ' },
      { name: 'article:section', content: 'Tailwind' },
      { name: 'article:tag', content: 'Tailwind' },
      { name: 'article:tag', content: 'CSS' }
    ]
  },

  data: () => ({
    postId: 5,
    createdAt: '2023-05-25',
    authors: [
      {
        nick: '@RALMAZ',
        link: 'https://ralmaz.pro'
      }
    ]
  }),

  computed: {
    code() {
      return codeData
    }
  },

  mounted() {
    this.initFingerVote(this.postId)
  }
}
</script>
